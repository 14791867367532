import React, { useRef, useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { useParams, useNavigate, Link, Navigate, useLocation } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import {   LockClosedIcon,
  ShieldCheckIcon,
  CheckCircleIcon, ShoppingCartIcon, ShareIcon } from '@heroicons/react/24/solid';
import { addToCart, getCartItemCount } from './cartUtils'; // Use the same cart utils
import 'bootstrap/dist/css/bootstrap.min.css';
import { toast } from 'react-toastify'; // Add toast for notifications
import { useAuth } from '../context/AuthContext';
import SimpleProductCard from '../components/common/SimpleProductCard';
import debounce from 'lodash.debounce';


function ProductDetail() {
  const { id } = useParams();
  const location = useLocation();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedPacking, setSelectedPacking] = useState(null);
  const [currentImage, setCurrentImage] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const navigate = useNavigate();
  const [cartItemCount, setCartItemCount] = useState(0);
  const [bestSellingProducts, setBestSellingProducts] = useState([]);
  const carouselRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState(''); // Added state for search query
  const [searchResults, setSearchResults] = useState([]); // Added state for search results
  const [searchLoading, setSearchLoading] = useState(false); // Added state for search loading

  useEffect(() => {
    setCartItemCount(getCartItemCount());
  }, []);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/products/product/${id}`
        );
        setProduct(response.data);
        if (response.data.packing_sizes.length > 0) {
          setSelectedPacking(response.data.packing_sizes[0]);
        }
        setLoading(false);
      } catch (err) {
        console.error('Failed to fetch product details:', err);
        setError('Failed to fetch product details.');
        setLoading(false);
        return; // Exit the function if fetching product details fails
      }
  
      // Fetch best-selling products separately
      try {
        const bestSellingResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/products/bestsellers/`
        );
        let products = bestSellingResponse.data.results || [];
  
        // Remove current product from the list
        products = products.filter((p) => p.id !== parseInt(id));
        const maxProducts = Math.min(products.length, 10);
        products = products.sort(() => 0.5 - Math.random()).slice(0, maxProducts);
        // Randomly select up to 10 products
        setBestSellingProducts(products);
      } catch (err) {
        console.error('Failed to fetch best-selling products:', err);
        // Optionally, you can set a separate error state for best-selling products
        // setBestSellingError('Failed to fetch best-selling products.');
        // Or proceed without setting an error to not affect the main page
      }
    };
  
    fetchProduct();
  }, [id]);
  



  const scrollCarousel = (direction) => {
    const { current } = carouselRef;
    if (current) {
      const scrollAmount = current.offsetWidth;
      if (direction === 'left') {
        current.scrollLeft -= scrollAmount;
      } else {
        current.scrollLeft += scrollAmount;
      }
    }
  };

  const handleProductClick = (productId) => {
    navigate(`/products/${productId}`);
  };

  const debouncedSearch = useCallback(
    debounce(async (query) => {
      if (!query) {
        setSearchResults([]);
        setSearchLoading(false);
        return;
      }

      setSearchLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/products/search/`,
          {
            params: { query },
          }
        );
        setSearchResults(response.data.results || []);
      } catch (error) {
        console.error('Failed to search products:', error);
      }
      setSearchLoading(false);
    }, 500),
    []
  );

  // Handle search input change
  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    debouncedSearch(query);
  };


  const handleAddToCart = () => {
    if (!selectedPacking) return;
    addToCart(product, selectedPacking, quantity);
    setCartItemCount(getCartItemCount());
  };

  const handlePurchaseNow = () => {
    if (!selectedPacking) {
      alert("Please select a packing size.");
      return;
    }
    addToCart(product, selectedPacking, quantity);
    navigate('/cart');
  };

  const handleThumbnailClick = (index) => {
    setCurrentImage(index);
  };

  const handleShareProduct = async (product) => {
    try {
      const baseUrl = process.env.REACT_APP_BASE_URL || window.location.origin;
      const productDetailUrl = `${baseUrl}/products/${product.id}/`;  
      let message = `*${product.name}*\n`; // Product Name in bold
  
      // Include Category
      if (product.category && product.category.name) {
        message += `📂 *Category*: ${product.category.name}\n`;
      }
  
      // Include Technical Name
      if (product.chemical_names && product.chemical_names.length > 0) {
        const chemicalNames = product.chemical_names.join(', ');
        message += `🧪 *Technical Name*: ${chemicalNames}\n`;
      }
  
      // Include Recommended Crops
      if (product.recommended_for_names && product.recommended_for_names.length > 0) {
        const cropNames = product.recommended_for_names.join(', ');
        message += `🌾 *Recommended for*: ${cropNames}\n`;
      }
  
      // Include Packing Sizes
      if (product.packing_sizes && product.packing_sizes.length > 0) {
        const packingSizes = product.packing_sizes.map((ps) => ps.size).join(', ');
        message += `📦 *Packing Sizes*: ${packingSizes}\n`;
      }
  
      message += `\n👉 *View more details*: <${productDetailUrl}>`;
  
      // Encode the message
      const encodedMessage = encodeURIComponent(message);
  
      // WhatsApp URL
      const whatsappUrl = `https://api.whatsapp.com/send?text=${encodedMessage}`;
  
      // Open WhatsApp with the pre-filled message
      window.open(whatsappUrl, '_blank');
  
      // Optional: Show a toast notification
      toast.success('Opening WhatsApp...');
    } catch (error) {
      console.error('Error sharing product:', error);
      toast.error('Sharing failed.');
    }
  };
  


  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <section className="bg-gray-50 min-h-screen">
      {/* Navigation Bar */}
      <nav className="bg-white shadow-md sticky top-0 z-50">
        <div className="container mx-auto px-6 py-4 flex items-center justify-between">
          {/* Logo */}
          <Link to="/ecom" className="flex items-center text-2xl font-bold text-gray-800">
            Krshak
          </Link>
  
          {/* Search Bar */}
          <div className="flex-1 mx-4">
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Search products by name, technical, brand, or category..."
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
  
          {/* Cart Icon with Count */}
          <div className="relative">
            <Link to="/cart">
              <ShoppingCartIcon className="h-8 w-8 text-gray-700" />
              {cartItemCount > 0 && (
                <span className="absolute -top-2 -right-2 bg-red-500 text-white text-xs rounded-full px-2 py-1">
                  {cartItemCount}
                </span>
              )}
            </Link>
          </div>
  
          {/* Share Icon */}
          <ShareIcon
            className="w-8 h-8 text-gray-700 cursor-pointer hover:text-blue-500 ml-4"
            onClick={() => handleShareProduct(product)}
          />
        </div>
      </nav>
  
      {/* Main Content */}
      <div className="max-w-7xl w-full mx-auto p-6 relative">
      {searchLoading && (
          <div className="flex items-center justify-center">
            <div className="text-gray-500 text-lg">Searching...</div>
          </div>
        )}

        {searchResults.length > 0 && (
          <>
            <h2 className="text-2xl font-bold mb-4 text-gray-800">
              Search Results for "{searchQuery}"
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
              {searchResults.map((product) => (
                <SimpleProductCard
                  key={product.id}
                  product={product}
                  onProductClick={() => handleProductClick(product.id)}
                />
              ))}
            </div>
          </>
        )}


        {/* No Search Results */}
        {searchResults.length === 0 && searchQuery && !searchLoading && (
          <p className="text-center text-gray-600 mt-4">
            No products found for "{searchQuery}".
          </p>
        )}

        {!searchQuery && (
                  <>
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Image Slider */}
          <div className="bg-white p-4 md:p-6 rounded-lg shadow-lg">
            {product.media.length > 0 ? (
              <>
                <Carousel
                  activeIndex={currentImage}
                  onSelect={setCurrentImage}
                  indicators={false}
                  controls={false}
                  className="relative"
                >
                  {product.media.map((image) => (
                    <Carousel.Item key={image.id}>
                      <img
                        src={image.display_image_url}
                        alt={product.name}
                        className="w-full h-auto object-contain rounded-lg"
                      />
                    </Carousel.Item>
                  ))}
                </Carousel>
  
                <div className="flex justify-center mt-4 space-x-2 overflow-x-auto">
                  {product.media.map((image, index) => (
                    <img
                      key={image.id}
                      src={image.display_image_url}
                      alt={`Thumbnail ${index + 1}`}
                      className={`w-16 h-16 object-cover rounded-md cursor-pointer ${
                        index === currentImage ? 'border-4 border-blue-500' : 'border'
                      }`}
                      onClick={() => handleThumbnailClick(index)}
                    />
                  ))}
                </div>
              </>
            ) : (
              <img
                src="https://via.placeholder.com/500x500.png?text=No+Image+Available"
                alt="No Image Available"
                className="w-full h-96 object-contain"
              />
            )}
          </div>
  
          {/* Product Info */}
          <div className="bg-white p-4 md:p-6 rounded-lg shadow-lg">
            <h1 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4 md:mb-6">
              {product.name}
            </h1>
  
            {/* Product Ratings */}
            <div className="flex items-center mb-4">
              <div className="flex items-center">
                <span className="text-yellow-400">★★★★★</span>
              </div>
              <p className="text-gray-600 ml-2">(200 reviews)</p>
            </div>
  
            {/* Brand */}
            <div className="mb-2 md:mb-4">
              <h3 className="font-semibold text-md md:text-lg text-gray-800">Brand</h3>
              <p className="text-gray-600 text-sm md:text-base">{product.brand.name}</p>
            </div>
  
            {/* Category */}
            <div className="mb-2 md:mb-4">
              <h3 className="font-semibold text-md md:text-lg text-gray-800">Category</h3>
              <p className="text-gray-600 text-sm md:text-base">{product.category.name}</p>
            </div>
  
            {/* Chemicals */}
            {product.chemical_names.length > 0 && (
              <div className="mb-4">
                <h3 className="font-semibold text-lg text-gray-800">Technical</h3>
                <ul className="list-disc list-inside">
                  {product.chemical_names.map((chemical, index) => (
                    <li key={index} className="text-gray-600">
                      {chemical}
                    </li>
                  ))}
                </ul>
              </div>
            )}
  
            {/* Recommended Crops */}
            {product.recommended_for_names.length > 0 && (
              <details className="mb-2 md:mb-4">
                <summary className="font-semibold text-md md:text-lg text-gray-800 cursor-pointer">
                  Crops
                </summary>
                <ul className="list-disc list-inside mt-2">
                  {product.recommended_for_names.map((crop, index) => (
                    <li key={index} className="text-gray-600">
                      {crop}
                    </li>
                  ))}
                </ul>
              </details>
            )}
  
            {/* Packing Sizes */}
            {product.packing_sizes.length > 0 && (
              <div className="mb-4 md:mb-6">
                <h3 className="font-semibold text-lg text-gray-800">Select Packing Size</h3>
                <div className="flex flex-wrap gap-2 mt-2">
                  {product.packing_sizes.map((packing) => (
                    <button
                      key={packing.id}
                      onClick={() => setSelectedPacking(packing)}
                      className={`px-4 py-2 border rounded-lg transition-colors duration-200 ${
                        selectedPacking && selectedPacking.id === packing.id
                          ? 'bg-blue-500 text-white border-blue-500'
                          : 'bg-gray-200 text-gray-700 hover:bg-blue-100'
                      }`}
                    >
                      {packing.size}
                    </button>
                  ))}
                </div>
              </div>
            )}
  
            {/* Pricing */}
            {selectedPacking && (
              <div className="my-4 md:my-6">
                <h3 className="font-semibold text-lg text-gray-800">Pricing</h3>
                <div className="flex items-center space-x-2">
                  <p className="text-red-500 line-through">
                    MRP: ₹{selectedPacking.price}
                  </p>
                  <p className="text-green-600 font-bold text-xl">
                    ₹{selectedPacking.discount_price}
                  </p>
                </div>
                <p className="text-gray-600">
                  You Save: ₹{selectedPacking.price - selectedPacking.discount_price}
                </p>
                <p className="text-gray-500 text-sm mt-1">*GST Including</p>
              </div>
            )}
  
            {/* Offer */}
            <div className="mb-4 bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4">
              <p className="font-semibold">
                यदि आप खरीद के बाद 30 दिनों में से कम से कम 28 दिनों तक हमारे ऐप से एसएमएस
                भेजते हैं, तो आप हमारे पास से खरीदे गए बिना बिके उत्पाद को उसी कीमत पर वापस
                कर सकते हैं।
              </p>
            </div>
  
            {/* Shipping Information */}
            <div className="mb-4">
              <p className="text-gray-700 font-semibold">
                Free Shipping | Delivery in 5-7 days
              </p>
            </div>
  
            {/* Trust Icons */}
            <div className="flex items-center space-x-4 mt-4">
              <div className="flex items-center">
                <LockClosedIcon className="w-6 h-6 text-gray-600" />
                <p className="text-gray-600 ml-2">Secure Payment</p>
              </div>
              <div className="flex items-center">
                <ShieldCheckIcon className="w-6 h-6 text-green-600" />
                <p className="text-gray-600 ml-2">Guaranteed Quality</p>
              </div>
              <div className="flex items-center">
                <CheckCircleIcon className="w-6 h-6 text-blue-600" />
                <p className="text-gray-600 ml-2">Certified Vendor</p>
              </div>
            </div>
  
            {/* Quantity Selector */}
            <div className="mb-6">
              <h3 className="font-semibold text-lg text-gray-800">Quantity</h3>
              <div className="flex items-center mt-2">
                <button
                  onClick={() => setQuantity(Math.max(1, quantity - 1))}
                  className="bg-gray-200 px-3 py-1 rounded-l-md hover:bg-gray-300"
                >
                  -
                </button>
                <input
                  type="number"
                  min="1"
                  value={quantity}
                  onChange={(e) =>
                    setQuantity(Math.max(1, parseInt(e.target.value) || 1))
                  }
                  className="w-12 text-center border-t border-b border-gray-200"
                />
                <button
                  onClick={() => setQuantity(quantity + 1)}
                  className="bg-gray-200 px-3 py-1 rounded-r-md hover:bg-gray-300"
                >
                  +
                </button>
              </div>
            </div>
  
            {/* Action Buttons */}
            <div className="flex flex-col md:flex-row md:space-x-4 space-y-2 md:space-y-0">
              <button
                onClick={handleAddToCart}
                className="w-full bg-blue-600 text-white py-3 px-4 rounded-lg hover:bg-blue-700 font-semibold flex items-center justify-center space-x-2 transition transform hover:scale-105"
              >
                <ShoppingCartIcon className="w-5 h-5" />
                <span>Add to Cart</span>
              </button>
              <button
                onClick={handlePurchaseNow}
                className="w-full bg-green-600 text-white py-3 px-4 rounded-lg hover:bg-green-700 font-semibold transition transform hover:scale-105"
              >
                Purchase Now
              </button>
            </div>
          </div>
        </div>
  
        {/* Product Details and Reviews */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-12">
          {/* Product Description */}
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="font-semibold text-lg text-gray-800 mb-2">Description</h3>
            <p className="text-gray-600">{product.description}</p>
          </div>
  
          {/* Customer Reviews */}
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="font-semibold text-lg text-gray-800 mb-2">
              Customer Reviews
            </h3>
            <p className="text-gray-600">⭐⭐⭐⭐☆ 4.5/5</p>
            <p className="text-gray-600 mt-2">
              "Great product! Very effective and good quality."
            </p>
          </div>
        </div>
  
        {/* Best-Selling Products Carousel */}
        {bestSellingProducts.length > 0 && (
          <div className="mt-12">
            <h2 className="text-2xl font-bold text-gray-800 mb-4">You May Also Like</h2>
            <div className="relative">
              {/* Scroll Buttons */}
              <button
                className="hidden md:block absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full focus:outline-none z-10"
                onClick={() => scrollCarousel('left')}
                aria-label="Scroll Left"
              >
                ❮
              </button>
              <button
                className="hidden md:block absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full focus:outline-none z-10"
                onClick={() => scrollCarousel('right')}
                aria-label="Scroll Right"
              >
                ❯
              </button>
  
              {/* Carousel Container */}
              <div className="flex overflow-x-auto space-x-4 px-8" ref={carouselRef}>
                {bestSellingProducts.map((product) => (
                  <div key={product.id} className="flex-shrink-0 w-1/2 md:w-1/6">
                    <SimpleProductCard
                      product={product}
                      onProductClick={handleProductClick}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
                  
                  </>)}


      </div>
    </section>
  );
  
}

export default ProductDetail;